<template>
  <div class="home-containter">
    <div class="header-containter">
      <div class="header-content">
        <!-- 给图片加一层背景黑色蒙层 -->
        <div class="header-bg" />

        <!-- 导航块块 -->
        <div class="navbar" :class="navClass">
          <div class="nav-title">
            <span>「人间值得」抽奖</span>
          </div>
          <div class="nav-btn-containter">
            <el-dropdown trigger="click" @command="handleNavClick">
              <span class="el-dropdown-link">
                <i class="el-icon-s-fold nav-btn" />
              </span>
              <el-dropdown-menu slot="dropdown" class="home-nav">
                <el-dropdown-item command="header">首页</el-dropdown-item>
                <el-dropdown-item command="business">抽奖功能</el-dropdown-item>
                <el-dropdown-item command="customerCase">
                  客户案例
                </el-dropdown-item>
                <el-dropdown-item command="activitys">
                  最新抽奖活动
                </el-dropdown-item>
                <el-dropdown-item command="contactus">
                  关于我们
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>

        <!-- banner内容块块 -->
        <header id="header" class="header">
          <span class="header-desc">构建一款服务私域社群的抽奖系统</span>
        </header>
      </div>
    </div>

    <div class="content-containter">
      <!-- 抽奖功能块块 && 客户案例块块 -->
      <div class="center" style="position: relative;">
        <!-- 青色蒙层的背景图片 -->
        <div class="center-bg" />

        <!-- 抽奖功能块块 -->
        <div id="business" class="content-business">
          <div class="title-containter">
            <span class="title">抽奖功能</span
            ><span class="desc">(逐步完善)</span>
          </div>
          <div class="detail-containter">
            <div class="detail-item">
              <div class="detail-item-box">
                <div class="item-logo">
                  <img src="@/assets/1.png" alt="" />
                </div>
                <span class="item-desc">定时抽奖</span>
              </div>
            </div>
            <div class="detail-item">
              <div class="detail-item-box">
                <div class="item-logo">
                  <img src="@/assets/2.png" alt="" />
                </div>
                <span class="item-desc">线下大屏抽奖</span>
              </div>
            </div>
          </div>
        </div>

        <!-- 客户案例块块 -->
        <div id="customerCase" class="customerCase">
          <div class="title-containter">
            <span class="title">客户案例</span>
          </div>
          <div class="detail-containter">
            <div class="detail-item">
              <div class="detail-item-box">
                <div class="item">
                  <div class="item-logo">
                    <img src="@/assets/stormzhang.jpg" alt="" />
                  </div>
                  <span class="item-desc">帅张和他的朋友们</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 最新活动块块 -->
      <div id="activitys" class="activitys">
        <div class="activitys-bg" />
        <div class="title-containter">
          <span class="title">最新公开抽奖活动</span>
        </div>
        <div class="detail-containter">
          <div class="activity-table">
            <el-table :data="activityList" style="width: 100%">
              <el-table-column
                :min-width="120"
                prop="activityName"
                label="奖项名称"
              >
                <template slot-scope="scope">
                  <span
                    class="activity-title"
                    @click="jumpActivity(scope.row)"
                    >{{ scope.row.activityName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="name" label="发起人" :min-width="100">
                <template>
                  <span>帅张和他的朋友们</span>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="状态" :min-width="80">
                <template slot-scope="scope">
                  <span>{{
                    scope.row.status === 0
                      ? '未开奖'
                      : scope.row.status === 1
                      ? '进行中'
                      : '已结束'
                  }}</span>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              :page-size="pageSize"
              class="pagination"
              layout="prev, pager, next"
              :total="total"
              @current-change="handleCurrentChange"
            />
          </div>
        </div>
      </div>

      <!-- 联系我们块块 -->
      <div id="contactus" class="contactus">
        <div class="contact-title">关于我们</div>
        <div class="contact-containter">
          <div class="contact-item">
            <div class="item-logo">
              <img src="@/assets/contact.png" alt="" />
            </div>
            <div class="desc">
              <span class="item-title contact" @click="showContactImg">
                商务合作
              </span>
              <span class="item-desc">企业微信：凉凉-张哥助手</span>
            </div>
            <img
              class="contact-img"
              src="@/assets/arrow.png"
              alt=""
              @click="showContactImg"
            />
          </div>
          <div class="contact-item">
            <div class="item-logo">
              <img src="@/assets/location.png" alt="" />
            </div>
            <div class="desc">
              <span class="item-title">办公地点</span>
              <span class="item-desc">云办公，线上小分队</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 联系方式弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      :close-on-press-escap="false"
      class="dialog-vx"
      title="商务合作请加微信"
      :visible.sync="dialogVisible"
      width="80%"
    >
      <div class="liangliangvx-box">
        <img class="liangliangvx" src="@/assets/liangliangvx.jpg" alt="" />
      </div>
    </el-dialog>

    <p class="footer">@ 2021 生活大作战 沪 ICP 备 19039321号-2 号</p>
  </div>
</template>

<script>
import { getActivityList } from '@/apis/activity';

export default {
  name: 'Index',
  data() {
    return {
      dialogVisible: false,
      pageNo: 1,
      pageSize: 5,
      total: 0,
      scrollMax: 300,
      navClass: '',
      activityList: [
        // {
        //   title: '小白的活动',
        //   name: '小白',
        //   time: '2020-02-04'
        // },
        // {
        //   title: '小白的活动',
        //   name: '小白',
        //   time: '2020-02-04'
        // },
        // {
        //   title: '小白的活动',
        //   name: '小白',
        //   time: '2020-02-04'
        // }
      ]
    };
  },
  created() {
    this.getActivityList();
  },
  mounted() {
    // 设置滚动渐变效果
    const stepTotal = 30;
    const stepHeight = this.scrollMax / stepTotal;
    this.$el.addEventListener('scroll', e => {
      if (this.$el.scrollTop >= this.scrollMax - 2) {
        this.navClass = `step${stepTotal - 1}`;
      } else {
        this.navClass = `step${Math.floor(
          stepTotal - (this.scrollMax - this.$el.scrollTop) / stepHeight
        )}`;
      }
    });
  },
  methods: {
    getActivityList() {
      const that = this;
      getActivityList({
        pageNo: that.pageNo,
        pageSize: that.pageSize
      })
        .then(res => {
          const data = that.handleResponse(res);
          if (data.code === 200) {
            that.total = data.total;
            that.activityList = data.data;
            // console.log(data)
          } else {
            this.$message({
              message: data.msg,
              type: 'error',
              duration: 3000,
              center: true,
              offset: 100
            });
          }
        })
        .catch(err => {
          this.$message({
            message: '查询列表失败！！！',
            type: 'error',
            duration: 3000,
            center: true,
            offset: 100
          });
          console.log(err);
        });
    },
    handleCurrentChange(val) {
      // 页码改吧
      // console.log(`当前页: ${val}`)
      this.pageNo = val;
      this.getActivityList();
    },
    showContactImg() {
      this.dialogVisible = true;
    },
    handleNavClick(command) {
      document.getElementById(command).scrollIntoView(true);
    },
    jumpActivity(row) {
      // this.$router.push({ path: '/index', query: { activeId: row.id, ts: Date.now()}})
      this.$router.push({
        name: 'Prizelist',
        query: { activeId: row.id, ts: Date.now() }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
$headerHeight: 300px;
$navHeight: 40px;

.home-containter {
  position: relative;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgb(252, 254, 254);

  .header-containter {
    position: relative;
    height: $headerHeight;
  }

  .navbar {
    z-index: 9;
    width: 100%;
    box-sizing: border-box;
    padding: 0 15px;
    height: $navHeight;
    position: fixed;
    display: flex;
  }
  $stepTotal: 30;
  @mixin steploop($i) {
    .navbar.step#{$i} {
      $op: $i / ($stepTotal - 1);
      background-color: rgba(6, 12, 12, round($op * 10000) / 10000);
    }
  }
  @for $i from 0 through $stepTotal {
    @include steploop($i);
  }

  .content-business, // 抽奖功能块块 && 客户案例块块
  .customerCase {
    // 客户案例
    position: relative;
    padding: 30px 5px 0 5px;
    background-color: rgba(252, 254, 254, 0.5);
  }
  .activitys {
    // 最新活动
    // background-attachment: fixed;
    background-image: url('../assets/bg3.jpg');
    background-size: 100% 100%;
    background-position: center center;
    padding-bottom: 2px;
    position: relative;
    background-color: #121212;
  }
  .contactus {
    // 联系我们
    background-color: #121212;
    color: rgb(218, 218, 218);
    margin-top: -2px;
  }
}

// 头部导航+banner块块
.header-containter {
  .header-content {
    background: url('../assets/bg2.jpg');
    // background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom center;
    position: static;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .header-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
  }

  .header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $navHeight 20% 10px 20%;
    box-sizing: border-box;
  }
}

.navbar {
  .nav-title,
  .nav-btn-containter {
    width: 50%;
    height: 100%;
    display: inline-block;
  }
  .nav-title {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
  }
  .nav-btn-containter {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
  }
}
.nav-btn-containter {
  .nav-btn {
    font-size: 14px;
    color: #fff;
    // color: #000;
    // font-weight: bold;
    cursor: pointer;
    &:hover {
      color: rgb(26, 180, 171);
    }
  }
  .nav-list {
    background: #e8e8e8;
  }
}

.header {
  .header-desc {
    margin-top: -5%;
    text-align: center;
    font-size: 25px;
    color: #fff;
    font-weight: bold;
    font-family: serif;
  }
}

.center {
  .center-bg {
    background-image: url('../assets/bgright.png');
    background-size: 100% 100%;
    background-position: center center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
}
.content-business,
.customerCase,
.activitys {
  .title-containter {
    text-align: center;
    position: relative;
    color: #333;
  }
  .detail-containter {
    box-sizing: border-box;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
  }
}
.title-containter {
  .title {
    font-size: 16px;
    font-weight: bold;
    margin-right: 2px;
  }
  .desc {
    font-size: 11px;
    color: #888;
    position: absolute;
    bottom: 0;
    line-height: 16px;
  }
}
.detail-containter {
  .detail-item {
    color: #111;
    font-weight: bold;
    flex: 1;
    position: relative;
    font-size: 11px;
    margin: 40px 20px 20px;
    max-width: 300px;
  }

  .activity-table {
    width: 90%;
    max-width: 800px;
    .activity-title {
      text-decoration: underline;
      color: rgb(26, 180, 171);
      cursor: pointer;
      &:hover {
        color: rgb(26, 180, 171);
      }
    }
  }

  .pagination {
    margin: 10px auto 6px auto;
    text-align: center;
  }
}
.detail-item {
  .detail-item-box {
    height: 100%;
    width: 80%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 15% 20px;
    background-color: #fbfbfb;
    box-shadow: 0px 0px 8px 0px rgba(100, 100, 100, 0.17);
    border-radius: 5px;
    // margin: 0 28%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    &:hover {
      background-color: #fff;
      .item-desc {
        color: rgb(26, 180, 171) !important;
      }
    }
  }
  .item-logo {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    // padding: 0 28%;
    & img {
      width: 50%;
      max-width: 60px;
      // border-radius: 50%;
    }
  }
  .item-desc {
    font-weight: bold;
  }
}
.customerCase {
  .detail-item {
    flex-wrap: nowrap;
    margin: 0;
    // margin-bottom: 30px;
    .detail-item-box {
      padding: 30px 10px;
      background-color: transparent;
      box-shadow: none;
    }
  }
  .item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px 20px;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px 0px rgba(100, 100, 100, 0.17);
    border-radius: 5px;
    transition: all 0.4s;
    -moz-transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    &:hover {
      transform: scale(1.1);
    }
    .item-desc {
      width: 100%;
    }
  }
  .item-logo {
    width: 80%;
    margin: 0 10px 0 0;
    display: inline-block;
    // width: 80px;
    padding: 0;
    // height: 80px;
    display: inline-block;
    img {
      width: 100%;
    }
  }
}
.activitys {
  .title-containter {
    margin-bottom: 15px;
    padding-top: 30px;
  }
  .title {
    color: #fff;
  }
  .activitys-bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.05);
  }
}
.contactus {
  .contact-title {
    box-sizing: border-box;
    padding: 30px 20px 18px 10px;
    font-size: 16px;
    font-weight: bold;
  }
  .contact-containter {
    box-sizing: border-box;
    border-top: 1px solid #2d2d2d;
  }
  .contact-item {
    padding: 10px 5px;
    display: flex;
    align-content: center;
    border-bottom: 1px solid #2d2d2d;
    position: relative;
  }
  .contact-img {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 15px;
    height: 15px;
    cursor: pointer;
  }
}
.contact-item {
  .item-logo img {
    width: 35px;
    margin-right: 20px;
  }
  .desc {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  .item-title {
    width: 100%;
    font-size: 12px;
    line-height: 12px;
    font-weight: bold;
    &.contact {
      cursor: pointer;
      &:hover {
        color: #1ab4ab;
      }
    }
  }
  .item-desc {
    width: 100%;
    line-height: 10px;
    font-size: 10px;
    color: #9c9c9c;
  }
}
.dialog-vx {
  max-width: 600px;
  margin: 0 auto;
  .liangliangvx-box {
    width: 100%;
    text-align: center;
  }
  .liangliangvx {
    // width: 94%;
    max-height: 200px;
    margin: 0 auto;
    display: inline-block;
  }
}
.footer {
  text-align: center;
  margin: 0 auto;
  background-color: #292b31;
  font-size: 8px;
  color: #888;
  display: block;
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .home-containter {
    box-shadow: #d0d0d0 0px 0px 10px;
    // width: 500px;
    // .navbar {
    //   width: 500px;
    // }
  }
  // .dialog-vx {
  //   width: 400px;
  //   margin: 0 auto;
  // }
}
</style>
<style lang="scss">
.home-nav.el-dropdown-menu {
  .el-dropdown-menu__item {
    font-size: 12px;
    line-height: 26px;
    // transform: translateX(-20px);
    // margin-left: 20px;
    // padding: 0 0 0 20px;
    &:hover,
    &:focus,
    &:not(.is-disabled):hover {
      background-color: rgba(26, 180, 171, 0.1);
      color: #1ab4ab;
      font-weight: bold;
    }
  }
}
.home-containter {
  .el-table td,
  .el-table th {
    padding: 8px 0;
  }
  .el-table th > .cell {
    padding-bottom: 2px;
  }
  .el-pagination .btn-next .el-icon,
  .el-pagination .btn-prev .el-icon {
    font-size: 12px;
    font-weight: bold;
  }
  .el-pager li {
    font-size: 12px;
  }
  .el-pagination {
    padding: 0;
  }
  .el-table,
  .el-table__expanded-cell,
  .el-table tr,
  .el-table--enable-row-transition .el-table__body td,
  .el-table .cell {
    background-color: transparent;
    font-weight: bold;
    font-size: 11px;
  }
  .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: rgba(26, 180, 171, 0.1);
  }
  .el-table td,
  .el-table th.is-leaf,
  .el-pagination button:disabled,
  .el-pagination .btn-next,
  .el-pagination .btn-prev,
  .el-pagination .btn-prev .btn-next {
    background-color: transparent;
    color: #dcdcdc;
  }
  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid #5f5f5f;
  }
  .el-table--border:after,
  .el-table--group:after,
  .el-table:before {
    background-color: #5f5f5f;
  }
  .el-table th {
    padding-bottom: 0;
  }
  .el-pagination {
    color: #aaa;
  }
  .el-pagination button:hover,
  .el-pager li:hover {
    color: rgb(26, 180, 171);
  }
  .el-pager {
    .number.active,
    & li {
      background-color: transparent;
    }
    .number.active {
      color: rgb(26, 180, 171);
    }
  }
}

// 弹窗
.dialog-vx {
  .el-dialog__title {
    font-size: 16px;
  }
}
</style>
